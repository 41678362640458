import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

// Theme
import Theme from 'styles/Global'

// Components
import Header from 'components/layout/Header/Header'
import Footer from 'components/layout/Footer'

// CSS
import 'swiper/swiper.min.css'
import 'swiper/components/navigation/navigation.min.css'
import 'bootstrap/dist/css/bootstrap.css'
import 'styles/all.css'
import Popup from 'components/elements/Misc/Popup'

export interface LayoutContextProps {
  locale: string
  translations: Array<{
    href: string
    locale: string
  }>
  setIsScrollable: Function
}

export const LayoutContext = React.createContext({
  locale: 'nl_NL',
  translations: [],
  setIsScrollable: (bool: any) => bool,
})

const Content = styled.div<{ isSticky?: boolean }>`
  @media (min-width: 992px) {
    padding-top: 150px;
  }
  @media (max-width: 991px) {
    padding-top: 75px;
  }
`

interface LayoutProps {
  children: any
  locale?: string
  translations?: any
}

const Layout: React.FC<LayoutProps> = ({
  children,
  locale = 'nl_NL',
  translations,
}) => {
  const [isSticky, setSticky] = useState<boolean>(false)
  const [isScrollable, setIsScrollable] = useState<boolean>(true)

  const handleScroll = () => {
    setSticky(window.scrollY > 0)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', () => handleScroll)
    }
  }, [handleScroll])

  return (
    <Theme isScrollable={isScrollable}>
      <LayoutContext.Provider value={{ setIsScrollable, locale, translations }}>
        <Header isSticky={isSticky} setIsScrollable={setIsScrollable} />
        <Content isSticky={isSticky}>{children}</Content>
        <Footer />
        <Popup />
      </LayoutContext.Provider>
    </Theme>
  )
}

export default Layout
