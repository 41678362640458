/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-lone-blocks */
/* eslint-disable react/jsx-no-bind */
// eslint-disable-next-line react/jsx-no-bind
import React, { useState, useEffect } from 'react'
import Modal from 'react-modal'

import ModalImage from 'img/Kokall-popup-Gesloten_Vakantie.png'
import styled from 'styled-components'

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '0',
  },
}

function Popup() {
  const [modalIsOpen, setIsOpen] = useState(false)
  useEffect(() => {
    setTimeout(() => {
      {
        !sessionStorage.getItem('hide') && setIsOpen(true)
      }
    }, 2000)
  }, [])

  function closeModal() {
    sessionStorage.setItem('hide', 'true')
    setIsOpen(false)
  }

  const Img = styled.img`
    @media (max-width: 991px){
      width: 100%;
      height: auto
    }

    width: 640px;
    height: 366px;
  `

  return (
    <div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="position-relative">
          <div
            className="position-absolute"
            style={{ right: '.5rem', top: '.5rem' }}
          >
            <button type="button" onClick={closeModal}>
              x
            </button>
          </div>
          <div onClick={closeModal} role="button">
            <Img src={ModalImage} alt="Weru" />
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default Popup
